import Print from 'vue-print-nb'
import VDistpicker from 'v-distpicker'
import vueEcharts from 'vue-echarts'
import 'echarts'

export default async ({ app, router, store, Vue }) => {
  Vue.component('v-distpicker', VDistpicker)
  Vue.component('v-chart', vueEcharts)
  Vue.use(Print)
  console.log('Welcome To ViseshPro')
  console.log("Home Page ------ https://viseshpro.viseshglobal.com/");
}
